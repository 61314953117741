<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn v-if="!inLesson" v-on="on" text color="blue" large>
          <m-icon
            icon="mdi-plus-circle"
            left
            class="mr-3"
            style="font-size: 30px"
          ></m-icon>

          Добавить
        </v-btn>
      </slot>
    </template>
    <v-list>
      <v-list-item
        v-if="!inLesson"
        key="checkList"
        @click="$emit('select', 'lesson')"
      >
        <v-list-item-icon>
          <m-icon
            class="pl"
            :icon="UtilGetIcon('view-MaterialEdit', 'lesson')"
          ></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Урок</v-list-item-title>
      </v-list-item>
      <v-list-item key="equipmentStandart" @click="$emit('select', 'video')">
        <v-list-item-icon>
          <m-icon
            class="pl"
            :icon="UtilGetIcon('view-MaterialEdit', 'video')"
          ></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Видео</v-list-item-title>
      </v-list-item>
      <v-list-item key="equipmentStandart2" @click="$emit('select', 'word')">
        <v-list-item-icon>
          <m-icon
            class="pl"
            :icon="UtilGetIcon('view-MaterialEdit', 'word')"
          ></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Документ Word</v-list-item-title>
      </v-list-item>
      <v-list-item
        key="equipmentStandart5"
        @click="$emit('select', 'presentation')"
      >
        <v-list-item-icon>
          <m-icon
            :icon="UtilGetIcon('view-MaterialEdit', 'presentation')"
          ></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Презентация</v-list-item-title>
      </v-list-item>
      <v-list-item key="equipmentStandart3" @click="$emit('select', 'doc')">
        <v-list-item-icon>
          <m-icon
            class="pl"
            :icon="UtilGetIcon('view-MaterialEdit', 'document')"
          ></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Локальный документ</v-list-item-title>
      </v-list-item>
      <v-list-item key="equipmentStandart4" @click="$emit('select', 'test')">
        <v-list-item-icon>
          <m-icon class="pl" :icon="UtilGetIcon('view-TestEdit')"></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Тест</v-list-item-title>
      </v-list-item>
      <v-list-item key="equipmentStandart5" @click="$emit('select', 'copy')">
        <v-list-item-icon>
          <m-icon class="pl" :icon="UtilGetIcon('CoursesCopy')"></m-icon>
        </v-list-item-icon>
        <v-list-item-title>Копировать из курса</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    inLesson: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.pl {
  padding-left: 2px;
}
</style>
