var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-tree-list-view',{attrs:{"dataSource":_vm.value,"headers":_vm.computedHeaders,"isNested":"","header-hidden":"","hide-default-footer":"","notShowSelect":"","exclude-settings-actions":['group'],"show-add-in-table":!_vm.readonly ? _vm.addMenu : false,"show-expand":"","show-actions":!_vm.isEmployee,"expand-icon":"mdi-comment-arrow-right","groupOpenType":'showFirst',"options":"Children","show-expanded-tree":false,"single-expand":false,"id":"knowledge-base__records","fixedWidthActions":"","dragable":"","notShowSettings":"","not-filter":""},on:{"customHandle":_vm.customHandle,"customAdd":_vm.customAdd},scopedSlots:_vm._u([{key:"item.Type",fn:function(ref){
var item = ref.item;
return [(item.Type === 0)?_c('m-icon',{staticClass:"pl",attrs:{"icon":_vm.UtilGetIcon('view-TestEdit')}}):_vm._e(),(item.Type === 1)?_c('m-icon',{staticClass:"pl",attrs:{"icon":_vm.UtilGetIcon('view-MaterialEdit', 'word')}}):_vm._e(),(item.Type === 2)?_c('m-icon',{staticClass:"pl",attrs:{"icon":_vm.UtilGetIcon('view-MaterialEdit', 'document')}}):_vm._e(),(item.Type === 3)?_c('m-icon',{staticClass:"pl",attrs:{"icon":_vm.UtilGetIcon('view-MaterialEdit', 'video')}}):_vm._e(),(item.Type === 4)?_c('m-icon',{attrs:{"icon":_vm.UtilGetIcon('view-MaterialEdit', 'presentation')}}):_vm._e()]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [(item.IsLesson && item.Name !== 'Урок')?_c('span',[_vm._v("Урок "+_vm._s(item.Num)+". "+_vm._s(item.Name))]):(item.IsLesson)?_c('span',[_vm._v("Урок "+_vm._s(item.Num))]):_c('span',[_vm._v(_vm._s(item.Name))])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(_vm.isEmployee && item.IsLesson && item.Status > 0)?_c('div',{staticClass:"knowledge-base__status-point__wrapper"},[_c('span',{staticClass:"knowledge-base__status-point",class:'knowledge-base__status-point--' + item.Status}),_vm._v(" Пройдено "),_c('b',[_vm._v(_vm._s(item.Completed || 0))]),_vm._v(" из "),_c('b',[_vm._v(_vm._s(item.Total || 0))])]):(_vm.isEmployee && !item.IsLesson)?_c('div',{staticClass:"knowledge-base__status-point__wrapper",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"knowledge-base__status-point",class:'knowledge-base__status-point--' +
          (item.Result ? 3 : item.Result === false ? 4 : 1)})]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.IsLesson && !_vm.readonly)?_c('menu-add-records',{attrs:{"inLesson":""},on:{"select":function($event){return _vm.addItem($event, item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"blue","icon":""}},on),[_c('m-icon',{attrs:{"icon":"mdi-plus-circle"}})],1)]}}],null,true)}):_vm._e(),(!item.IsLesson && (!_vm.readonly || _vm.isCurator))?_c('select-employees',{attrs:{"source-employees":_vm.employees.map(function (e) { return e.Employee; }),"dialogTitle":"Выбор сотрудников, у которых нужно обновить материал","button-save-text":"Применить"},on:{"input":function($event){return _vm.appointMaterial($event, item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","title":"Обновить материал у сотрудников"}},on),[_c('m-icon',{attrs:{"icon":"mdi-sync","size":"20px"}})],1)]}}],null,true)}):_vm._e(),(!_vm.readonly)?_c('v-btn',{attrs:{"icon":"","color":"error","title":"Удалить"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteElement(item)}}},[_c('m-icon',{attrs:{"icon":"tool-delete"}})],1):_vm._e()]}}])},[_c('copy-records',{attrs:{"dialogTitle":"Выбор сотрудников, у которых нужно обновить материал","button-save-text":"Применить"},on:{"save":_vm.addNewLessons},model:{value:(_vm.showCopy),callback:function ($$v) {_vm.showCopy=$$v},expression:"showCopy"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }